<template>
  <div class="modal-content">
    <button type="button" class="close" @click="$emit('closeModal')">
      <span aria-hidden="true"><svg v-svg symbol="x" size="0 0 17 17" /></span>
    </button>
    <div class="modal-body">
      <div class="title-h2 pb-2">
        {{ $t("platform.settings.auto_subscription") }}
      </div>
      <div class="tubs-content__item">
        <div class="code">
          <div class="hidden">
            <textarea readonly id="htttpsCopyCode" v-model="platformCode" />
          </div>
          <button type="button" class="btn _has-icon" @click="copy">
            <span class="icon-copy">
              <svg v-svg symbol="copy" size="0 0 13 15" role="info" /> </span
            >{{ $t("copy") }}
          </button>
          <code v-html="code" class="code__content"> </code>
        </div>
        <notifications group="message" />
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/_tubs.scss";
import "@/assets/css/_code.scss";
import "@/assets/css/_modal.scss";

export default {
  name: "PlatformModal",
  data() {
    return {
      code: `
      &lt;script&gt; </br>
      &emsp;if (typeof window._phv2Activator === "undefined") { </br>
      &emsp;&emsp;document.addEventListener('phv2site.ready', (e) => { </br>
      &emsp;&emsp;&emsp;window._phv2Activator.activate('native_web_push'); </br>
      &emsp;&emsp;}); </br>
      &emsp;} else { </br>
      &emsp;&emsp;window._phv2Activator.activate('native_web_push'); </br>
      &emsp;} </br>
      &lt;/script&gt;
      `,
    };
  },
  computed: {
    platformCode() {
      return (
        `<script> \n` +
        `if (typeof window._phv2Activator === "undefined") {\n` +
        `document.addEventListener('phv2site.ready', (e) => {\n` +
        `window._phv2Activator.activate('native_web_push');\n` +
        `});\n` +
        `} else {\n` +
        `window._phv2Activator.activate('native_web_push');\n` +
        `}\n` +
        `</` +
        `script>`
      );
    },
  },
  methods: {
    copy() {
      this.$helper.copyCode("htttpsCopyCode");
      this.$notify({
        group: "message",
        title: this.$t("copied_succ"),
        ignoreDuplicates: true,
      });
    },
  },
};
</script>