<template>
  <div class="c-example">
    <div class="screen thumb">
      <img :src="require('@/assets/images/home/screen-bg.jpg')" alt="" />
      <div class="screen__opacity" style="opacity: 0.6"></div>
      <div class="screen__txt">
        <div class="screen__title">{{ title }}</div>
        <div class="screen__desc">
          {{ text }}
        </div>
      </div>
      <div class="screen__pop">
        <div class="screen__pop-title">
          <span class="icon-notification">
            <svg
              v-svg
              role="presentation"
              size="0 0 12 14"
              symbol="menu-3" /></span
          >{{ $t("dashboard.statistic.subscription") }}
        </div>
        <div class="screen__pop-btn">
          <div class="screen__pop-cancel">{{ $t("cancel") }}</div>
          <div class="screen__pop-allow">{{ $t("allow") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ExampleScreen",
  props: ["title", "text"],
};
</script>