<template>
  <div class="col-12 col-xl-6">
    <div class="row">
      <div class="col-12">
        <div class="form-item">
          <label class="form-item__label info-popup">
            {{ $t("platform.settings.pop.pop_type") }}
            <span
              v-tooltip="$t('platform.settings.description.onclick.pop_type')"
            >
              <svg v-svg symbol="info" size="0 0 18 18" role="info" />
            </span>
          </label>
          <multiselect
            class="multiselect-wrapper form-item__field"
            v-model="pop_type"
            :options="pop_type_options"
            :searchable="false"
            placeholder=""
            :show-labels="false"
            :allowEmpty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <span>{{
                $t(`platform.settings.pop.pop_types.${option.title}`)
              }}</span>
            </template>
            <template slot="option" slot-scope="{ option }">
              <span>{{
                $t(`platform.settings.pop.pop_types.${option.title}`)
              }}</span>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="col-12">
        <div class="form-item">
          <div class="form-item__label info-popup">
            {{ $t("platform.settings.pop.selector") }}
            <span
              v-tooltip="$t('platform.settings.description.onclick.selector')"
            >
              <svg v-svg symbol="info" size="0 0 18 18" role="info" />
            </span>
          </div>
          <div class="form-item__field">
            <input type="text" v-model.trim="selector" />
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 form-input-wrapper">
        <Counter
          v-model="display_frequency"
          :max="10"
          :min="1"
          :title="'platform.settings.pop.display_frequency'"
          :description="'platform.settings.description.onclick.display_frequency'"
        />
      </div>
      <div class="col-12 col-sm-6 col-md-4 form-input-wrapper">
        <Counter
          v-model="period"
          :max="period_time_unit && period_time_unit.id === 3 ? 48 : 60"
          :min="0"
          :title="'platform.settings.pop.period'"
          :description="'platform.settings.description.onclick.period'"
        />
      </div>
      <div class="col-12 col-sm-12 col-md-4 form-input-wrapper">
        <div class="form-item">
          <label class="form-item__label info-popup">
            {{ $t("platform.settings.pop.time_unit") }}
            <span
              v-tooltip="
                $t('platform.settings.description.onclick.time_units_period')
              "
            >
              <svg v-svg symbol="info" size="0 0 18 18" role="info" />
            </span>
          </label>
          <multiselect
            class="multiselect-wrapper multiselect-w-100 form-item__field"
            v-model="period_time_unit"
            :options="period_unit_options"
            :searchable="false"
            placeholder=""
            :show-labels="false"
            :allowEmpty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <span>{{
                $t(`platform.settings.pop.time_units.${option.title}`)
              }}</span>
            </template>
            <template slot="option" slot-scope="{ option }">
              <span>{{
                $t(`platform.settings.pop.time_units.${option.title}`)
              }}</span>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="col-12 mb-4 pt-4">
        <h3>{{ $t("platform.settings.pop.click_condition") }}</h3>
      </div>
      <div class="col-12 col-md-6">
        <Counter
          v-model="interval"
          :max="60"
          :min="0"
          :title="'platform.settings.pop.interval'"
          :description="'platform.settings.description.onclick.interval'"
        />
      </div>
      <div class="col-12 col-md-6">
        <div class="form-item">
          <label class="form-item__label info-popup">
            {{ $t("platform.settings.pop.time_unit") }}
            <span
              v-tooltip="
                $t('platform.settings.description.onclick.time_units_interval')
              "
            >
              <svg v-svg symbol="info" size="0 0 18 18" role="info" />
            </span>
          </label>
          <multiselect
            class="multiselect-wrapper multiselect-w-100 form-item__field"
            v-model="interval_time_unit"
            :options="interval_unit_options"
            :searchable="false"
            placeholder=""
            :show-labels="false"
            :allowEmpty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <span>{{
                $t(`platform.settings.pop.time_units.${option.title}`)
              }}</span>
            </template>
            <template slot="option" slot-scope="{ option }">
              <span>{{
                $t(`platform.settings.pop.time_units.${option.title}`)
              }}</span>
            </template>
          </multiselect>
        </div>
      </div>
      <button @click.prevent="sendRequest" class="btn mb-2 _mob100">
        {{ $t("save") }}
      </button>
    </div>
    <notifications group="message" />
  </div>
</template>
<script>
import Counter from "../Counter.vue";
import siteSettingsService from "@/api/siteSettingsService";

export default {
  name: "OnClick",
  components: {
    Counter,
  },
  data() {
    return {
      display_frequency: 1,
      selector: "",
      pop_type: { id: 1, title: "new_tab" },
      pop_type_options: [
        { id: 1, title: "new_tab" },
        { id: 2, title: "new_tab_left" },
        { id: 3, title: "replace" },
        { id: 4, title: "popup" },
      ],
      interval: 0,
      interval_time_unit: { id: 1, title: "seconds", value: 1 },
      interval_unit_options: [
        { id: 1, title: "seconds", value: 1 },
        { id: 2, title: "minutes", value: 60 },
      ],
      period: 0,
      period_time_unit: { id: 1, title: "seconds", value: 1 },
      period_unit_options: [
        { id: 1, title: "seconds", value: 1 },
        { id: 2, title: "minutes", value: 60 },
        { id: 3, title: "hours", value: 3600 },
      ],
    };
  },
  watch: {
    period_time_unit() {
      if (this.period_time_unit.id === 3) {
        if (this.period > 48) {
          this.period = 48;
        }
      }
    },
  },
  mounted() {
    siteSettingsService.getOnClick(this.$route.params.id).then((resp) => {
      if (resp.status !== 200) {
        console.log("No data");
      } else if (resp && resp.status === 200) {
        this.selector = resp.data.selector;
        this.pop_type = this.setPopType(resp.data.type);
        this.display_frequency = resp.data.capping.limit;
        this.interval_time_unit = this.interval_unit_options.find(
          (el) => el.value === resp.data.delayUnit
        ) || { id: 2, title: "seconds", value: 1 };
        this.interval = resp.data.delay / resp.data.delayUnit || 0;
        this.period_time_unit = this.period_unit_options.find(
          (el) => el.value === resp.data.capping.durationUnit
        ) || { id: 2, title: "seconds", value: 1 };
        this.period =
          resp.data.capping.duration / resp.data.capping.durationUnit || 0;
      }
    });
  },
  methods: {
    sendRequest() {
      const payload = {
        enable: true,
        id: this.$route.params.id,
        open_type: this.pop_type.title,
        frequency_limit: this.display_frequency,
        selector: this.selector,
        period: this.period,
        unit_of_time: this.period_time_unit.value,
        delay_interval: this.interval,
        unit_of_delay: this.interval_time_unit.value,
      };

      siteSettingsService.setOnClick(payload).then((res) => {
        if (res && res.status === 400) {
          this.$notify({
            group: "message",
            type: "error",
            ignoreDuplicates: true,
            title: this.$t("sent_error"),
            text: this.$t("save_failed"),
          });
        } else if (res && res.status === 200) {
          this.$notify({
            group: "message",
            ignoreDuplicates: true,
            title: `${this.$t("save_successfully")}`,
          });
        }
      });
    },
    setPopType(type) {
      const defaultOption = { id: 1, title: "new_tab" };
      const selectedOption = this.pop_type_options.find(
        (option) => option.title === type
      );
      return selectedOption || defaultOption;
    },
  },
};
</script>
<style lang="scss" scoped>
.settings-container {
  display: flex;
}

.multiselect-wrapper {
  ::v-deep {
    .multiselect__content-wrapper {
      @media (min-width: 768px) {
        width: max-content;
        max-width: max-content;
      }
      @media (max-width: 768px) {
        list-style: none;
        padding: 0;
        margin: 0;
        white-space: nowrap;
        overflow-x: auto;
      }
    }
    .multiselect__tags {
      .multiselect__single {
        position: relative;
        white-space: nowrap;
        overflow: hidden;

        &::after {
          position: absolute;
          content: "";
          width: 10px;
          height: 100%;
          background: #f5f5f5;
          right: -10px;
          box-shadow: -5px 0px 20px 20px #f5f5f5;
        }
      }
    }
  }
}
.multiselect-w-100 {
  ::v-deep {
    .multiselect__content-wrapper {
      width: 100%;
      max-width: 100%;
    }
  }
}

.form-input-wrapper {
  .form-item {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
