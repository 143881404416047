<template>
  <div class="col-12">
    <h1>{{ $t("platform.create_new") }}</h1>
    <div class="type-selection">
      <div class="type-selection__body">
        <div class="row pt-2 flex-nowrap">
          <div
            @click="$emit('setNav', nav)"
            class="col-auto check-item"
            v-for="nav in navs"
            :key="nav.id"
          >
            <input type="checkbox" :checked="nav.selected" />
            <span
              class="check-item__content"
              :class="{ active: currentNav.name === nav.name }"
            >
              <span
                @click.stop="toggleNav(nav)"
                class="check-item__visible"
              ></span>
              <span class="check-item__txt">{{ nav.name }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <h2 class="mb-5 d-flex align-items-center">
      {{ currentNav.name }}
      <span class="is-enabled" :class="currentNavSelected ? 'pos' : 'neg'">
        {{ currentNavSelected ? $t("turn_on") : $t("turn_off") }}
      </span>
    </h2>
    <notifications group="message" />
  </div>
</template>
<script>
import siteSettingsService from "@/api/siteSettingsService";

export default {
  name: "",
  props: {
    currentNav: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      navs: [
        {
          id: 1,
          name: "Native Web Push",
          value: "NativeWebPush",
          title: "native_web_push",
          selected: false,
        },
        // { id: 2, name: "Http Web Push", title: "http_web_push" value: "HttpWebPush" },
        {
          id: 3,
          name: "InPage",
          value: "InPage",
          title: "in_page",
          selected: false,
        },
        {
          id: 4,
          name: "OnClick",
          value: "OnClick",
          title: "on_click",
          selected: false,
        },
        // { id: 5, name: "Back Button Web Push", title: "back_button_web_push" value: "BackButtonWebPush" },
        // { id: 6, name: "OnClick Web Push", title: "onclick_web_push" value: "OnClickWebPush" },
        // { id: 7, name: "iOS Calendar", title: "ios_calendar" value: "IOSCalendar" },
        // { id: 8, name: "Native", title: "native", value: "Native" },
      ],
    };
  },
  created() {
    const id = this.$route.params.id;
    siteSettingsService.getFormats(id).then((res) => {
      if (res && res.status !== 200) {
        console.log(res.message);
      } else if (res && res.status === 200) {
        this.navs = this.navs.map((el) => {
          if (el.title in res.data) {
            el.selected = res.data[el.title];
          }
          return el;
        });
      }
    });
  },
  computed: {
    currentNavSelected() {
      return this.navs.find((el) => el.value === this.currentNav.value)
        .selected;
    },
  },
  methods: {
    toggleNav(selectedNav) {
      const id = this.$route.params.id;
      const payload = {
        title: selectedNav.name,
        enabled: !selectedNav.selected,
      };
      siteSettingsService.setSubAccountFormat(id, payload).then((res) => {
        if (res && res.status !== 200) {
          this.$notify({
            group: "message",
            type: "error",
            ignoreDuplicates: true,
            title: this.$t("sent_error"),
            text: res.message,
          });
        } else if (res && res.status === 200) {
          this.$notify({
            group: "message",
            ignoreDuplicates: true,
            title: `${this.$t("save_successfully")}`,
          });
        }
      });
      this.navs.forEach((nav) => {
        if (nav.value === selectedNav.value) nav.selected = !nav.selected;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.type-selection {
  margin: 0 0 45px;
  position: relative;
  &:after {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(
      270deg,
      #ffffff 27.63%,
      rgba(255, 255, 255, 0.24) 61.18%
    );
    width: 20px;
    pointer-events: none;
  }
  .type-selection__body {
    overflow-x: auto;
    @media screen and (max-width: 1200px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .row {
    margin: 0;
    .col-auto {
      &:first-child {
        padding-left: 0;
      }
    }
  }
  .check-item {
    white-space: nowrap;
    margin-bottom: 5px;
  }
  @media screen and (max-width: 1200px) {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.check-item__content.active {
  border-color: #38807d;
}

.is-enabled {
  position: relative;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  padding: 7px 12px;
  border-radius: 5px;
  margin-left: 14px;

  &::after {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    top: 50%;
    z-index: -1;
    left: -5px;
    transform: translateY(-50%) rotate(-45deg);
    border-radius: 2px;
  }

  &.neg {
    background-color: #c54747;

    &::after {
      background-color: #c54747;
    }
  }

  &.pos {
    background-color: #37807d;

    &::after {
      background-color: #37807d;
    }
  }
}
</style>