<template>
  <div class="col-12">
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <div class="row">
          <div class="col-12 col-lg-6">
            <Counter
              v-model="delay"
              :max="60"
              :placeholder="$t('sec') + '.'"
              :min="0"
              :title="'platform.settings.inpage.delay'"
              :description="'platform.settings.description.inpage.delay'"
            />
          </div>
          <div class="col-12 col-lg-6">
            <label class="form-item__label info-popup">
              {{ $t("platform.settings.inpage.position") }}
              <span
                v-tooltip="$t('platform.settings.description.inpage.position')"
              >
                <svg v-svg symbol="info" size="0 0 18 18" role="info" />
              </span>
            </label>
            <multiselect
              class="multiselect-wrapper multiselect-w-100"
              v-model="position"
              :options="positionOptions"
              :searchable="false"
              placeholder=""
              :show-labels="false"
              :allowEmpty="false"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                <span>
                  {{ option.value }}
                </span>
              </template>
              <template slot="option" slot-scope="{ option }">
                <span>{{ option.value }} </span>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 space-for-sm">
            <Counter
              v-model="delay_after_click"
              :max="600"
              :min="0"
              :placeholder="$t('sec') + '.'"
              :title="'platform.settings.inpage.pause_after_click'"
              :description="'platform.settings.description.inpage.pause_after_click'"
            />
          </div>
          <div class="col-12 col-lg-6">
            <Counter
              v-model="delay_after_close"
              :max="600"
              :placeholder="$t('sec') + '.'"
              :min="0"
              :title="'platform.settings.inpage.pause_after_close'"
              :description="'platform.settings.description.inpage.pause_after_close'"
            />
          </div>
        </div>
        <button @click.prevent="sendRequest" class="btn mb-2 _mob100">
          {{ $t("save") }}
        </button>
      </div>
      <div class="col-sm-12 col-lg-6">
        <div class="banner-wrapper">
          <span class="title">{{ $t("platform.settings.inpage.title") }}</span>
          <span class="info">{{
            $t("platform.settings.inpage.description")
          }}</span>
          <img src="@/assets/images/in-page-banner.png" class="banner" />
        </div>
      </div>
    </div>
    <notifications group="message" />
  </div>
</template>
<script>
import Counter from "../Counter.vue";
import siteSettingsService from "@/api/siteSettingsService";

export default {
  name: "InPage",
  components: {
    Counter,
  },
  data() {
    return {
      delay: 0,
      position: { id: 4, value: "bottom-right" },
      delay_after_click: 0,
      delay_after_close: 0,
      positionOptions: [
        { id: 1, value: "top-left" },
        { id: 2, value: "top-right" },
        { id: 3, value: "bottom-left" },
        { id: 4, value: "bottom-right" },
      ],
    };
  },
  mounted() {
    const id = this.$route.params.id;
    siteSettingsService.getInPage(id).then((resp) => {
      if (resp.data.length === 0) {
        console.log("No data");
      } else {
        this.delay = resp.data.delay;
        this.position.value = resp.data.position;
        this.delay_after_click = resp.data.delay_after_click;
        this.delay_after_close = resp.data.delay_after_close;
      }
    });
  },
  methods: {
    sendRequest() {
      const id = this.$route.params.id;
      const payload = {
        delay: this.delay,
        position: this.position.value,
        delay_after_click: this.delay_after_click,
        delay_after_close: this.delay_after_close,
      };

      siteSettingsService.setInPage(id, payload).then((res) => {
        if (res && res.status === 400) {
          this.$notify({
            group: "message",
            type: "error",
            ignoreDuplicates: true,
            title: this.$t("sent_error"),
            text: res.message,
          });
        } else if (res && res.status === 500) {
          this.$notify({
            group: "message",
            type: "error",
            ignoreDuplicates: true,
            title: this.$t("sent_error"),
            text: this.$t("save_failed"),
          });
        } else if (res && res.status === 200) {
          this.$notify({
            group: "message",
            ignoreDuplicates: true,
            title: `${this.$t("save_successfully")}`,
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.settings-container {
  display: flex;
}

@media screen and (max-width: 991px) {
  .space-for-sm {
    padding-top: 20px;
  }
}

.banner-wrapper {
  position: relative;
  span.title,
  span.info {
    position: absolute;
    top: 13vw;
    left: 9.5vw;
    font-size: 15px;
    font-weight: 600;
    @media screen and (max-width: 997px) {
      top: 32.5vw;
      left: 22.5vw;
    }
    @media screen and (min-width: 998px) and (max-width: 1200px) {
      top: 15.5vw;
      left: 12vw;
    }
  }
  span.info {
    top: 14.5vw;
    font-weight: 400;
    font-size: 14px;
    @media screen and (max-width: 997px) {
      top: 36.5vw;
    }
    @media screen and (min-width: 998px) and (max-width: 1200px) {
      top: 18vw;
    }
  }
}
img.banner {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.multiselect-wrapper {
  ::v-deep {
    .multiselect__content-wrapper {
      @media (min-width: 768px) {
        width: max-content;
        max-width: max-content;
      }
      @media (max-width: 768px) {
        list-style: none;
        padding: 0;
        margin: 0;
        white-space: nowrap;
        overflow-x: auto;
      }
    }
    .multiselect__tags {
      .multiselect__single {
        position: relative;
        white-space: nowrap;
        overflow: hidden;

        &::after {
          position: absolute;
          content: "";
          width: 10px;
          height: 100%;
          background: #f5f5f5;
          right: -10px;
          box-shadow: -5px 0px 20px 20px #f5f5f5;
        }
      }
    }
  }
}
.multiselect-w-100 {
  ::v-deep {
    .multiselect__content-wrapper {
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>