<template>
  <div>
    <NavBar :currentNav="currentNav" @setNav="setCurrentNav" />
    <keep-alive>
      <component :is="currentNav.value"></component>
    </keep-alive>
  </div>
</template>
<script>
import NavBar from "@/components/PlatformSettings/NavBar.vue";
import NativeWebPush from "@/components/PlatformSettings/Blocks/NativeWebPush.vue";
import OnClick from "@/components/PlatformSettings/Blocks/OnClick.vue";
import InPage from "@/components/PlatformSettings/Blocks/InPage.vue";

export default {
  name: "PlatformSettings",
  components: { NavBar, NativeWebPush, OnClick, InPage },
  data() {
    return {
      currentNav: null,
    };
  },
  created() {
    if (localStorage.getItem("current-format") === "InPage") {
      this.currentNav = { id: 3, name: "InPage", value: "InPage" };
    } else if (localStorage.getItem("current-format") === "OnClick") {
      this.currentNav = { id: 4, name: "OnClick", value: "OnClick" };
    } else
      this.currentNav = {
        id: 1,
        name: "Native Web Push",
        value: "NativeWebPush",
      };
  },
  methods: {
    setCurrentNav(nav) {
      localStorage.setItem("current-format", nav.value);
      this.currentNav = nav;
    },
  },
};
</script>