var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fields-section pb-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-item"},[_c('div',{staticClass:"form-item__label info-popup"},[_vm._v(" "+_vm._s(_vm.$t("platform.settings.service_name"))+" "),(_vm.description)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t(("" + _vm.description))),expression:"$t(`${description}`)"}]},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"symbol":"info","size":"0 0 18 18","role":"info"}})]):_vm._e()]),_c('div',{staticClass:"form-item__field"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data),expression:"data"}],class:{
              invalid:
                _vm.is_service_name_invalid ||
                _vm.$v.$invalid ||
                !_vm.error.service_name.maxLength ||
                !_vm.error.service_name.minLength,
            },attrs:{"type":"text","disabled":_vm.disable},domProps:{"value":(_vm.data)},on:{"input":function($event){if($event.target.composing){ return; }_vm.data=$event.target.value}}}),(_vm.data === '')?_c('small',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):(_vm.is_service_name_invalid || _vm.$v.$invalid)?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t("platform.settings.check_service_name"))+" ")]):(!_vm.error.service_name.minLength && _vm.data !== '')?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t("min_len_pass", { number: "3" })))]):(!_vm.error.service_name.maxLength && _vm.data !== '')?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t("max_len_pass", { number: "120" })))]):_vm._e()])])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-item pb-0"},[_c('div',{staticClass:"form-item__field"},[_c('div',{staticClass:"row align-items-center pt-2"},[_c('div',{staticClass:"col-12 col-md-4 mb-4"},[_c('button',{staticClass:"btn _border w-100",attrs:{"disabled":!_vm.data.length ||
                  _vm.$v.$invalid ||
                  _vm.is_service_name_invalid ||
                  !_vm.error.service_name.maxLength ||
                  !_vm.error.service_name.minLength},on:{"click":function($event){$event.preventDefault();return _vm.downloadFile($event)}}},[_c('span',{staticClass:"icon-download mr-2"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"symbol":"download","size":"0 0 17 17","role":"info"}})]),_vm._v(" "+_vm._s(_vm.$t("download"))+" ")])]),_c('div',{staticClass:"col-12 col-md-8 mb-4"},[_c('div',{staticClass:"message"},[_c('div',{staticClass:"message__item icon-info _gray _bg-off mb-0"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"message-icon",attrs:{"symbol":"info","size":"0 0 17 17","role":"info"}}),_c('p',[_vm._v(" "+_vm._s(_vm.$t("platform.settings.download_service_file"))+" ")])])])])])])])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-item pb-0"},[_c('div',{staticClass:"form-item__label"},[_vm._v(" "+_vm._s(_vm.$t("platform.settings.check_service"))+" ")]),_c('div',{staticClass:"form-item__field mb-0"},[_c('div',{staticClass:"row align-items-center pt-2"},[_c('div',{staticClass:"col-12 col-md-4 mb-4"},[_c('button',{staticClass:"btn _border _green w-100",attrs:{"disabled":!_vm.data.length ||
                  _vm.disable ||
                  _vm.$v.$invalid ||
                  _vm.is_service_name_invalid ||
                  !_vm.error.service_name.maxLength ||
                  !_vm.error.service_name.minLength},on:{"click":function($event){$event.preventDefault();return _vm.checkServiceWorker($event)}}},[_vm._v(" "+_vm._s(_vm.$t("platform.site.confirm_site.check"))+" ")])]),_c('div',{staticClass:"col-12 col-md-auto mb-4"},[(_vm.status === 1)?_c('div',{staticClass:"status"},[_c('div',{staticClass:"status__label"},[_vm._v(" "+_vm._s(_vm.$t("platform.site.confirm_site.status"))+": ")]),_c('div',{staticClass:"status__result"},[_c('div',{staticClass:"status__load"},[_vm._v(" "+_vm._s(_vm.$t("platform.site.confirm_site.checking"))+" ")])])]):(_vm.status === 2)?_c('div',{staticClass:"status"},[_c('div',{staticClass:"status__label"},[_vm._v(" "+_vm._s(_vm.$t("platform.site.confirm_site.status"))+": ")]),_c('div',{staticClass:"status__result"},[_c('div',{staticClass:"status__confirmed"},[_c('span',{staticClass:"icon-confirm"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"symbol":"confirm","size":"0 0 22 22","role":"info"}})]),_vm._v(" "+_vm._s(_vm.$t("platform.site.confirm_site.confirm"))+" ")])])]):(_vm.status === 3)?_c('div',{staticClass:"status"},[_c('div',{staticClass:"status__label"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("platform.site.confirm_site.status"))+": ")])]),_c('div',{staticClass:"status__result"},[_c('div',{staticClass:"status__error"},[_c('span',{staticClass:"icon-no-confirm"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"symbol":"no-confirm","size":"0 0 22 22","role":"info"}})]),_c('span',[_vm._v(" "+_vm._s(_vm.error_message)+" ")])])])]):_vm._e()])])])])])]),_c('notifications',{attrs:{"group":"message"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }