<template>
  <div class="form-item">
    <div class="form-item__label" :class="{ 'info-popup': description }">
      {{ $t(title) }}
      <span v-if="description" v-tooltip="$t(`${description}`)">
        <svg v-svg symbol="info" size="0 0 18 18" role="info" />
      </span>
    </div>
    <div class="form-item__field">
      <div class="form-item-number">
        <input
          :ref="title"
          type="number"
          :value="data"
          @input="setData($event)"
          @focus="$refs[title].select()"
        />
        <button
          @click="increment"
          :disabled="value >= max"
          class="form-item-number__btn icon-a-up"
        >
          <svg v-svg role="presentation" size="0 0 10 10" symbol="prev" />
        </button>
        <button
          @click="decrement"
          :disabled="value <= min"
          class="form-item-number__btn icon-a-down"
        >
          <svg v-svg role="presentation" size="0 0 10 10" symbol="next" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Counter",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", +newVal);
      },
    },
  },
  methods: {
    setData(event) {
      const value = +event.target.value;
      if (value > this.max) {
        event.target.value = this.max;
      } else if (value <= this.min) {
        event.target.value = this.min;
      } else {
        event.target.value = Math.trunc(
          +String(value).replace(/^(0{2,})/g, "0")
        );
      }
      this.data = event.target.value;
    },
    decrement() {
      if (this.data >= this.min) this.data--;
    },
    increment() {
      if (this.data <= this.max) this.data++;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-item-number__btn {
  svg {
    transform: rotate(90deg);
  }
  &:hover {
    svg {
      fill: white;
    }
  }
}
.form-item-number {
  small {
    top: 14px;
    right: 50px;
    position: absolute;
  }
}
</style>