<template>
  <div class="settings-container">
    <div class="col-12 col-xl-6">
      <ServiceWorker
        :status="service_status"
        :name="service_name"
        :error="$v"
        :disable="isServiceDisabled"
        :description="'platform.settings.description.native_web_push.service_name'"
        v-model="service_name"
        @setStatus="setStatus"
      />
      <DelaySettings
        :class="{ 'disable-delay': checkSubscription }"
        :disable="checkSubscription"
        :delay="delay_value"
        v-model="delay_value"
        :min="0"
        :max="60"
        :variable="{ key: 's' }"
        :title="'platform.settings.delay'"
        :description="'platform.settings.description.native_web_push.delay_settings'"
      />
      <DelaySettings
        :delay="blackout_range"
        v-model="blackout_range"
        :min="0"
        :max="100"
        :variable="'%'"
        :title="'platform.settings.blackout_range'"
        :description="'platform.settings.description.native_web_push.blackout_settings'"
        class="pb-2"
      />
      <BlackoutSettings
        :disabled="blackout_range === 0"
        :text="blackout_text"
        :title="blackout_title"
        :error="$v"
        @setTitle="setBlackoutTitle"
        @setText="setBlackoutText"
      />
      <SubscriptionRequest
        :subscription="checkSubscription"
        v-model="checkSubscription"
      />
      <button
        :disabled="service_status !== 2"
        @click.prevent="sendRequest"
        class="btn mb-2 _mob100"
      >
        {{ $t("save") }}
      </button>
    </div>
    <div class="col-12 col-xl-6 d-none d-xl-block">
      <ExampleScreen
        :disabled="service_status === 3"
        :title="blackout_title"
        :text="blackout_text"
      />
    </div>
    <notifications group="message" />
  </div>
</template>
<script>
import siteSettingsService from "@/api/siteSettingsService";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import ServiceWorker from "@/components/PlatformSettings/ServiceWorker.vue";
import DelaySettings from "../DelaySettings.vue";
import BlackoutSettings from "../BlackoutSettings.vue";
import ExampleScreen from "../ExampleScreen.vue";
import SubscriptionRequest from "../SubscriptionRequest.vue";

export default {
  name: "NativeWebPush",
  components: {
    ServiceWorker,
    DelaySettings,
    BlackoutSettings,
    ExampleScreen,
    SubscriptionRequest,
  },
  mixins: [validationMixin],
  validations: {
    service_name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(120),
    },
    blackout_title: {
      minLength: minLength(3),
      maxLength: maxLength(120),
    },
    blackout_text: {
      minLength: minLength(3),
      maxLength: maxLength(250),
    },
  },
  data() {
    return {
      service_name: "partners",
      service_status: 0,
      delay_value: 0,
      blackout_title: "",
      blackout_text: "",
      blackout_range: 0,
      checkSubscription: false,
      isServiceDisabled: false,
      isEnabled: null,
    };
  },
  mounted() {
    siteSettingsService.getNativeWebPus(this.$route.params.id).then((resp) => {
      if (resp.data.length === 0) {
        console.log("No data");
      } else {
        this.delay_value = resp.data.delay;
        this.blackout_range = resp.data.dimming;
        this.blackout_title = resp.data.caption;
        this.blackout_text = resp.data.description;
        this.checkSubscription = resp.data.lazy_activation;

        if (resp.data.worker_name) {
          this.isServiceDisabled = true;
          this.service_name = resp.data.worker_name;
          this.service_status = 2;
        }
      }
    });
  },
  methods: {
    setBlackoutTitle(val) {
      this.blackout_title = val;
    },
    setBlackoutText(val) {
      this.blackout_text = val;
    },
    setStatus(val) {
      this.service_status = val;
    },
    sendRequest() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const id = this.$route.params.id;
      const payload = {
        enable: true,
        id: this.$route.params.id,
        worker_name: this.service_name,
        delay: this.delay_value,
        dimming: this.blackout_range,
        caption: this.blackout_title,
        description: this.blackout_text,
        lazy_activation: this.checkSubscription,
      };

      siteSettingsService.setNativeWebPush(id, payload).then((res) => {
        if (res && res.status === 400) {
          this.$notify({
            group: "message",
            type: "error",
            ignoreDuplicates: true,
            title: this.$t("sent_error"),
            text: this.$t("save_failed"),
          });
        } else if (res && res.status === 200) {
          this.$notify({
            group: "message",
            ignoreDuplicates: true,
            title: `${this.$t("save_successfully")}`,
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.settings-container {
  display: flex;
}

.disable-delay {
  opacity: 0.6;
}
</style>
