import AxiosService from "@/services/AxiosService";

class siteSettingsService {
  async getNativeWebPus(id) {
    return await AxiosService.get(`/subaccount/${id}/native-web-push`, {})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async setNativeWebPush(id, payload) {
    return await AxiosService.post(`/subaccount/${id}/native-web-push`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async setSubAccountFormat(id, payload) {
    return await AxiosService.post(`/subaccount/${id}/settings-status`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  async getInPage(id) {
    return await AxiosService.get(`/subaccount/${id}/in-page`,{})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async setInPage(id, payload) {
    return await AxiosService.post(`/subaccount/${id}/in-page`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getFormats(id,payload) {
    return await AxiosService.get(`/subaccount/${id}/settings-status`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getServiceWorker(payload) {
    return await AxiosService.get(`/service-worker?worker_name=${payload}`, {})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async checkServiceWorker(payload) {
    return await AxiosService.post("/check-service-worker", payload)
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async getOnClick(payload) {
    return await AxiosService.get(`/subaccount/${payload}/on-click`, {})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async setOnClick(payload) {
    return await AxiosService.post(`/subaccount/${payload.id}/on-click`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

export default new siteSettingsService();
